/* Snackbar на мобильной версии вверху */
@media screen and (max-width: 768px) {
  .Snackbar {
    top: 0;
    bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .Card {
    width: 27% !important;
  }
}

@media screen and (min-width: 1039px) {
  .Card {
    width: 22% !important;
  }
}

:root {
  --background_content: none !important;
}

.Card {
  background-color: transparent !important;
}

.Card,
.Card__in {
  display: flex;
  align-items: stretch;
}

.HorizontalScroll__in {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
}

.Separator,
.CardScroll__gap {
  display: none !important;
}

/* Fix в слайдере додатков слева и справа сложно нажать dodaj, оно скролит */
.HorizontalScroll__arrow {
  width: 0;
}

.HorizontalScroll__arrow-right {
  margin-right: 56px !important;
}

.ModalCard__icon {
  margin: -16px !important;
  margin-bottom: 16px !important;
}

/* Snackbar на мобильной версии вверху */
@media screen and (max-width: 768px) {
  .Snackbar {
    top: 0;
    bottom: auto !important;
  }
}
